<template>
  <div class="event-notice-box">
    <div v-if="eventList.length > 0" style="padding-top: 20px">
      <div>设备位置： {{ eventList[index].position }}</div>
      <div>告警原因： {{ eventList[index].alarmWord }}</div>
      <div>设备编号： {{ eventList[index].deviceNo }}</div>
      <div>告警时间： {{ eventList[index].time }}</div>
    </div>
    <div style="position: absolute; bottom: 20px; right: 20px">
      <el-button-group>
        <el-button
          @click="backIndex"
          :disabled="index === 0"
          v-if="eventList.length > 1"
          size="mini"
          icon="el-icon-arrow-left"
        ></el-button>
        <el-button
          @click="nextIndex"
          :disabled="index === eventList.length - 1"
          v-if="eventList.length > 1"
          size="mini"
          ><i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
        <el-button @click="handleInfo" size="mini" type="primary"
          >详情</el-button
        >
      </el-button-group>
    </div>
  </div>
</template>

<script>
import { Notification } from "element-ui";

export default {
  name: "EventLogNotice",
  props: {},
  data() {
    return {
      index: 0,
      position: "",
      alarmWord: "",
      time: "",
      deviceNo: "",
      eventList: [],
    };
  },
  methods: {
    backIndex() {
      if (this.index > 0) {
        this.index = this.index - 1;
      }
    },

    nextIndex() {
      if (this.index < this.eventList.length - 1) {
        this.index = this.index + 1;
      }
    },
    handleInfo() {
      this.$emit("toEventDetail", this.eventList[this.index].id);

      this.eventList.splice(this.index, 1);

      if (this.index > this.eventList.length - 1) {
        this.index = this.eventList.length - 1;
      }

      if (this.eventList.length == 0) {
        Notification.closeAll();
      }
    },
    pushEvent(data) {
      // 切换为从数组头部添加 最新的消息 放在最前面
      // this.eventList.push(data);
      // 防止内存溢出 最多只展示50条
      if (this.eventList.length >= 50) {
        this.eventList.pop();
      }
      this.eventList.unshift(data);
      this.index = 0;
      console.log(data);
    },
  },
};
</script>

<style scoped lang="scss">
.event-notice-box {
  width: 230px;
  height: 160px;
}
</style>
