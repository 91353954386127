var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "div",
        { staticClass: "panel-tab__content--title" },
        [
          _vm._m(0),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.openModel("message")
                },
              },
            },
            [_vm._v("创建新消息")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.messageList, size: "mini", border: "" } },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60px" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "消息ID",
              prop: "id",
              "max-width": "300px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "消息名称",
              prop: "name",
              "max-width": "300px",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "panel-tab__content--title",
          staticStyle: {
            "padding-top": "8px",
            "margin-top": "8px",
            "border-top": "1px solid #eeeeee",
          },
        },
        [
          _vm._m(1),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.openModel("signal")
                },
              },
            },
            [_vm._v("创建新信号")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.signalList, size: "mini", border: "" } },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60px" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "信号ID",
              prop: "id",
              "max-width": "300px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "信号名称",
              prop: "name",
              "max-width": "300px",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.modelVisible,
            title: _vm.modelConfig.title,
            "close-on-click-modal": false,
            width: "400px",
            "append-to-body": "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.modelVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.modelObjectForm,
                size: "mini",
                "label-width": "90px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.modelConfig.idLabel } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.modelObjectForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelObjectForm, "id", $$v)
                      },
                      expression: "modelObjectForm.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.modelConfig.nameLabel } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.modelObjectForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.modelObjectForm, "name", $$v)
                      },
                      expression: "modelObjectForm.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.modelVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.addNewObject },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", {
        staticClass: "el-icon-menu",
        staticStyle: { "margin-right": "8px", color: "#555555" },
      }),
      _vm._v("消息列表"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", {
        staticClass: "el-icon-menu",
        staticStyle: { "margin-right": "8px", color: "#555555" },
      }),
      _vm._v("信号列表"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }