var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-process-designer" },
    [
      _c(
        "div",
        { staticClass: "my-process-designer__header" },
        [
          _vm._t("control-header"),
          !_vm.$slots["control-header"]
            ? [
                _c(
                  "el-button-group",
                  { key: "file-control" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: _vm.headerButtonSize,
                          icon: "el-icon-folder-opened",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.refFile.click()
                          },
                        },
                      },
                      [_vm._v("打开文件")]
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: _vm.headerButtonSize,
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadProcessAsXml()
                                  },
                                },
                              },
                              [_vm._v("下载为XML文件")]
                            ),
                            _c("br"),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: _vm.headerButtonSize,
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadProcessAsSvg()
                                  },
                                },
                              },
                              [_vm._v("下载为SVG文件")]
                            ),
                            _c("br"),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: _vm.headerButtonSize,
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadProcessAsBpmn()
                                  },
                                },
                              },
                              [_vm._v("下载为BPMN文件")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: _vm.headerButtonSize,
                              icon: "el-icon-download",
                            },
                          },
                          [_vm._v("下载文件")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: _vm.headerButtonSize,
                                  type: "text",
                                },
                                on: { click: _vm.previewProcessXML },
                              },
                              [_vm._v("预览XML")]
                            ),
                            _c("br"),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: _vm.headerButtonSize,
                                  type: "text",
                                },
                                on: { click: _vm.previewProcessJson },
                              },
                              [_vm._v("预览JSON")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: _vm.headerButtonSize,
                              icon: "el-icon-view",
                            },
                          },
                          [_vm._v("预览")]
                        ),
                      ],
                      1
                    ),
                    _vm.simulation
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "light",
                              content: this.simulationStatus
                                ? "退出模拟"
                                : "开启模拟",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: _vm.headerButtonSize,
                                  icon: "el-icon-cpu",
                                },
                                on: { click: _vm.processSimulation },
                              },
                              [_vm._v(" 模拟 ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-button-group",
                  { key: "align-control" },
                  [
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "向左对齐" } },
                      [
                        _c("el-button", {
                          staticClass: "align align-left",
                          attrs: {
                            size: _vm.headerButtonSize,
                            icon: "el-icon-s-data",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("left")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "向右对齐" } },
                      [
                        _c("el-button", {
                          staticClass: "align align-right",
                          attrs: {
                            size: _vm.headerButtonSize,
                            icon: "el-icon-s-data",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("right")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "向上对齐" } },
                      [
                        _c("el-button", {
                          staticClass: "align align-top",
                          attrs: {
                            size: _vm.headerButtonSize,
                            icon: "el-icon-s-data",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("top")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "向下对齐" } },
                      [
                        _c("el-button", {
                          staticClass: "align align-bottom",
                          attrs: {
                            size: _vm.headerButtonSize,
                            icon: "el-icon-s-data",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("bottom")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "水平居中" } },
                      [
                        _c("el-button", {
                          staticClass: "align align-center",
                          attrs: {
                            size: _vm.headerButtonSize,
                            icon: "el-icon-s-data",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("center")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "垂直居中" } },
                      [
                        _c("el-button", {
                          staticClass: "align align-middle",
                          attrs: {
                            size: _vm.headerButtonSize,
                            icon: "el-icon-s-data",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.elementsAlign("middle")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button-group",
                  { key: "scale-control" },
                  [
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "缩小视图" } },
                      [
                        _c("el-button", {
                          attrs: {
                            size: _vm.headerButtonSize,
                            disabled: _vm.defaultZoom < 0.2,
                            icon: "el-icon-zoom-out",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processZoomOut()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("el-button", { attrs: { size: _vm.headerButtonSize } }, [
                      _vm._v(
                        _vm._s(Math.floor(this.defaultZoom * 10 * 10) + "%")
                      ),
                    ]),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "放大视图" } },
                      [
                        _c("el-button", {
                          attrs: {
                            size: _vm.headerButtonSize,
                            disabled: _vm.defaultZoom > 4,
                            icon: "el-icon-zoom-in",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processZoomIn()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "重置视图并居中" } },
                      [
                        _c("el-button", {
                          attrs: {
                            size: _vm.headerButtonSize,
                            icon: "el-icon-c-scale-to-original",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processReZoom()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button-group",
                  { key: "stack-control" },
                  [
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "撤销" } },
                      [
                        _c("el-button", {
                          attrs: {
                            size: _vm.headerButtonSize,
                            disabled: !_vm.revocable,
                            icon: "el-icon-refresh-left",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processUndo()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "恢复" } },
                      [
                        _c("el-button", {
                          attrs: {
                            size: _vm.headerButtonSize,
                            disabled: !_vm.recoverable,
                            icon: "el-icon-refresh-right",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.processRedo()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tooltip",
                      { attrs: { effect: "light", content: "重新绘制" } },
                      [
                        _c("el-button", {
                          attrs: {
                            size: _vm.headerButtonSize,
                            icon: "el-icon-refresh",
                          },
                          on: { click: _vm.processRestart },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: _vm.headerButtonSize,
                      type: _vm.headerButtonType,
                      icon: "el-icon-plus",
                      disabled: _vm.simulationStatus,
                    },
                    on: { click: _vm.processSave },
                  },
                  [_vm._v("保存模型")]
                ),
              ]
            : _vm._e(),
          _c("input", {
            ref: "refFile",
            staticStyle: { display: "none" },
            attrs: { type: "file", id: "files", accept: ".xml, .bpmn" },
            on: { change: _vm.importLocalFile },
          }),
        ],
        2
      ),
      _c("div", { staticClass: "my-process-designer__container" }, [
        _c("div", {
          ref: "bpmn-canvas",
          staticClass: "my-process-designer__canvas",
        }),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "预览",
            width: "80%",
            visible: _vm.previewModelVisible,
            "append-to-body": "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewModelVisible = $event
            },
          },
        },
        [
          _c("pre", [
            _c("code", {
              staticClass: "hljs",
              domProps: {
                innerHTML: _vm._s(
                  _vm.highlightedCode(_vm.previewType, _vm.previewResult)
                ),
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }