var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.flowConditionForm,
            "label-width": "90px",
            size: "mini",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "流转类型" } },
            [
              _c(
                "el-select",
                {
                  on: { change: _vm.updateFlowType },
                  model: {
                    value: _vm.flowConditionForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.flowConditionForm, "type", $$v)
                    },
                    expression: "flowConditionForm.type",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "普通流转路径", value: "normal" },
                  }),
                  _c("el-option", {
                    attrs: { label: "默认流转路径", value: "default" },
                  }),
                  _c("el-option", {
                    attrs: { label: "条件流转路径", value: "condition" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.flowConditionForm.type === "condition"
            ? _c(
                "el-form-item",
                { key: "condition", attrs: { label: "条件格式" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.flowConditionForm.conditionType,
                        callback: function ($$v) {
                          _vm.$set(_vm.flowConditionForm, "conditionType", $$v)
                        },
                        expression: "flowConditionForm.conditionType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "表达式", value: "expression" },
                      }),
                      _c("el-option", {
                        attrs: { label: "脚本", value: "script" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.flowConditionForm.conditionType &&
          _vm.flowConditionForm.conditionType === "expression"
            ? _c(
                "el-form-item",
                { key: "express", attrs: { label: "表达式" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "192px" },
                    attrs: { clearable: "" },
                    on: { change: _vm.updateFlowCondition },
                    model: {
                      value: _vm.flowConditionForm.body,
                      callback: function ($$v) {
                        _vm.$set(_vm.flowConditionForm, "body", $$v)
                      },
                      expression: "flowConditionForm.body",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.flowConditionForm.conditionType &&
          _vm.flowConditionForm.conditionType === "script"
            ? [
                _c(
                  "el-form-item",
                  { key: "language", attrs: { label: "脚本语言" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      on: { change: _vm.updateFlowCondition },
                      model: {
                        value: _vm.flowConditionForm.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.flowConditionForm, "language", $$v)
                        },
                        expression: "flowConditionForm.language",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { key: "scriptType", attrs: { label: "脚本类型" } },
                  [
                    _c(
                      "el-select",
                      {
                        model: {
                          value: _vm.flowConditionForm.scriptType,
                          callback: function ($$v) {
                            _vm.$set(_vm.flowConditionForm, "scriptType", $$v)
                          },
                          expression: "flowConditionForm.scriptType",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "内联脚本", value: "inlineScript" },
                        }),
                        _c("el-option", {
                          attrs: { label: "外部脚本", value: "externalScript" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.flowConditionForm.scriptType === "inlineScript"
                  ? _c(
                      "el-form-item",
                      { key: "body", attrs: { label: "脚本" } },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", clearable: "" },
                          on: { change: _vm.updateFlowCondition },
                          model: {
                            value: _vm.flowConditionForm.body,
                            callback: function ($$v) {
                              _vm.$set(_vm.flowConditionForm, "body", $$v)
                            },
                            expression: "flowConditionForm.body",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.flowConditionForm.scriptType === "externalScript"
                  ? _c(
                      "el-form-item",
                      { key: "resource", attrs: { label: "资源地址" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          on: { change: _vm.updateFlowCondition },
                          model: {
                            value: _vm.flowConditionForm.resource,
                            callback: function ($$v) {
                              _vm.$set(_vm.flowConditionForm, "resource", $$v)
                            },
                            expression: "flowConditionForm.resource",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }