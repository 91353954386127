var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "el-form",
        {
          attrs: { size: "mini", "label-width": "90px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "回路特性" } },
            [
              _c(
                "el-select",
                {
                  on: { change: _vm.changeLoopCharacteristicsType },
                  model: {
                    value: _vm.loopCharacteristics,
                    callback: function ($$v) {
                      _vm.loopCharacteristics = $$v
                    },
                    expression: "loopCharacteristics",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: "并行多重事件",
                      value: "ParallelMultiInstance",
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "时序多重事件",
                      value: "SequentialMultiInstance",
                    },
                  }),
                  _c("el-option", {
                    attrs: { label: "循环事件", value: "StandardLoop" },
                  }),
                  _c("el-option", { attrs: { label: "无", value: "Null" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.loopCharacteristics === "ParallelMultiInstance" ||
          _vm.loopCharacteristics === "SequentialMultiInstance"
            ? [
                _c(
                  "el-form-item",
                  { key: "loopCardinality", attrs: { label: "循环基数" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      on: { change: _vm.updateLoopCardinality },
                      model: {
                        value: _vm.loopInstanceForm.loopCardinality,
                        callback: function ($$v) {
                          _vm.$set(_vm.loopInstanceForm, "loopCardinality", $$v)
                        },
                        expression: "loopInstanceForm.loopCardinality",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    key: "collection",
                    attrs: { label: "集合" },
                  },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      on: { change: _vm.updateLoopBase },
                      model: {
                        value: _vm.loopInstanceForm.collection,
                        callback: function ($$v) {
                          _vm.$set(_vm.loopInstanceForm, "collection", $$v)
                        },
                        expression: "loopInstanceForm.collection",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { key: "elementVariable", attrs: { label: "元素变量" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      on: { change: _vm.updateLoopBase },
                      model: {
                        value: _vm.loopInstanceForm.elementVariable,
                        callback: function ($$v) {
                          _vm.$set(_vm.loopInstanceForm, "elementVariable", $$v)
                        },
                        expression: "loopInstanceForm.elementVariable",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { key: "completionCondition", attrs: { label: "完成条件" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      on: { change: _vm.updateLoopCondition },
                      model: {
                        value: _vm.loopInstanceForm.completionCondition,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.loopInstanceForm,
                            "completionCondition",
                            $$v
                          )
                        },
                        expression: "loopInstanceForm.completionCondition",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { key: "async", attrs: { label: "异步状态" } },
                  [
                    _c("el-checkbox", {
                      attrs: { label: "异步前" },
                      on: {
                        change: function ($event) {
                          return _vm.updateLoopAsync("asyncBefore")
                        },
                      },
                      model: {
                        value: _vm.loopInstanceForm.asyncBefore,
                        callback: function ($$v) {
                          _vm.$set(_vm.loopInstanceForm, "asyncBefore", $$v)
                        },
                        expression: "loopInstanceForm.asyncBefore",
                      },
                    }),
                    _c("el-checkbox", {
                      attrs: { label: "异步后" },
                      on: {
                        change: function ($event) {
                          return _vm.updateLoopAsync("asyncAfter")
                        },
                      },
                      model: {
                        value: _vm.loopInstanceForm.asyncAfter,
                        callback: function ($$v) {
                          _vm.$set(_vm.loopInstanceForm, "asyncAfter", $$v)
                        },
                        expression: "loopInstanceForm.asyncAfter",
                      },
                    }),
                    _vm.loopInstanceForm.asyncAfter ||
                    _vm.loopInstanceForm.asyncBefore
                      ? _c("el-checkbox", {
                          attrs: { label: "排除" },
                          on: {
                            change: function ($event) {
                              return _vm.updateLoopAsync("exclusive")
                            },
                          },
                          model: {
                            value: _vm.loopInstanceForm.exclusive,
                            callback: function ($$v) {
                              _vm.$set(_vm.loopInstanceForm, "exclusive", $$v)
                            },
                            expression: "loopInstanceForm.exclusive",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.loopInstanceForm.asyncAfter ||
                _vm.loopInstanceForm.asyncBefore
                  ? _c(
                      "el-form-item",
                      {
                        key: "timeCycle",
                        attrs: { label: "重试周期", prop: "timeCycle" },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          on: { change: _vm.updateLoopTimeCycle },
                          model: {
                            value: _vm.loopInstanceForm.timeCycle,
                            callback: function ($$v) {
                              _vm.$set(_vm.loopInstanceForm, "timeCycle", $$v)
                            },
                            expression: "loopInstanceForm.timeCycle",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }