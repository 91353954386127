var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [
              !_vm.$route.meta.link
                ? _c("router-view", { key: _vm.key })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("iframe-toggle"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }