var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("svg-icon", {
        attrs: {
          "icon-class": _vm.isFullscreen ? "exit-fullscreen" : "fullscreen",
        },
        on: { click: _vm.click },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }