<template>
  <div id="app">
    <router-view />
    <theme-picker />
    <audio ref="audio">
      <source src="./assets/sound/baojing.mp3" />
    </audio>
  </div>
</template>

<script>
import ThemePicker from "@/components/ThemePicker";
import EventLogNotice from "@/components/EventLogNotice";
import { getAccessToken } from "@/utils/auth";
import { parseTime } from "@/utils/ruoyi";

import { getAlarmEventPage } from "@/api/system/alarmEvent";

export default {
  name: "App",
  components: { ThemePicker, EventLogNotice },
  metaInfo() {
    return {
      title:
        this.$store.state.settings.dynamicTitle &&
        this.$store.state.settings.title,
      titleTemplate: (title) => {
        return title
          ? `${title} - ${process.env.VUE_APP_TITLE}`
          : process.env.VUE_APP_TITLE;
      },
    };
  },
  created() {
    let oldAccessToken = getAccessToken();

    setInterval(() => {
      let newAccessToken = getAccessToken();

      if (!oldAccessToken && newAccessToken) {
        window.location = window.location;
      } else if (oldAccessToken && oldAccessToken == newAccessToken) {
        //console.log("用户未变" + ( new Date()));
      } else if (oldAccessToken && oldAccessToken != newAccessToken) {
        //console.log("用户发生改变");
        window.location = window.location;
      }
    }, 2000);

    if (!getAccessToken()) {
      return;
    }
    // 如果用户已经登录过 则进行连接websocket
    this.initWebSocket();
  },
  methods: {
    initWebSocket() {
      //process.env.VUE_APP_BASE_API +
      const wsUrl =
        "https://jingshou.aiyinzhi.com/infra/ws" +
        "?token=" +
        getAccessToken();
      let url = wsUrl.replace("http", "ws");
      // let url =
      //   "wss://jingshou.aiyinzhi.com/infra/ws" + "?token=" + getAccessToken();
      if (!"WebSocket" in window) {
        this.$modal.msgError("您的浏览器不支持WebSocket,将无法接收报警消息");
        return;
      }
      // 建立连接
      this.ws = new WebSocket(url);
      // 监听 open 事件
      this.ws.onopen = (event) => {
        // this.$modal.msgSuccess("消息引擎连接成功！");
      };
      // 监听 message 事件
      this.ws.onmessage = (event) => {
        try {
          const data = event.data;
          // 1. 收到心跳
          if (data === "pong") {
            return;
          }
          // 2.1 解析 type 消息类型
          const jsonMessage = JSON.parse(data);
          const type = jsonMessage.type;
          const content = JSON.parse(jsonMessage.content);
          if (!type) {
            this.$modal.msgError("未知的消息类型：" + data);
            return;
          }
          // 2.2 消息类型：alarm-event-message-receive 消息类型是报警警告
          if (type === "alarm-event-message-receive" || type === "smoke-event-message-receive") {
            const single = content.single;
            console.log(content);
            this.pushEvent(content);
            return;
          }
          console.error("未处理消息：" + data);
        } catch (error) {
          this.$modal.msgError("处理消息发生异常：" + event.data);
          console.error(error);
        }
      };
      // 监听 close 事件
      this.ws.onclose = (event) => {
        this.$modal.msgError("消息引擎连接关闭！");
      };
      // 监听 error 事件
      this.ws.error = (event) => {
        this.$modal.msgError("消息引擎连接异常！");
      };
    },
    toEventDetail(id) {
      this.$router.push("/alarm/event/data/" + id);
    },
    pushEvent(event) {
      console.log(event);

      if (typeof this.$refs.eventLogNoticeBox == "undefined") {
        if(this.$route.path.indexOf("preview") != -1){
          return;
        }
        const h = this.$createElement;
        this.$notify({
          type: "warning",
          position: "bottom-right",
          duration: 0,
          title: "设备告警通知",
          message: h("event-log-notice", {
            ref: "eventLogNoticeBox",
            on: {
              toEventDetail: this.toEventDetail,
            },
          }),
        });
      }

      this.$refs.eventLogNoticeBox.pushEvent({
        position: event.roomName,
        deviceNo: event.deviceNo,
        alarmWord: event.eventWord,
        time: parseTime(event.happenTime),
        id: event.eventId,
      });

      if (this.$refs.audio) {
        this.$refs.audio.play();
      }
    },
  },
};
</script>
<style scoped>
#app .theme-picker {
  display: none;
}
</style>
