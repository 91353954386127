var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("theme-picker"),
      _c("audio", { ref: "audio" }, [
        _c("source", { attrs: { src: require("./assets/sound/baojing.mp3") } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }