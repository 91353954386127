import request from '@/utils/request'

// 创建事件管理
export function createAlarmEvent(data) {
  return request({
    url: '/system/alarm-event/create',
    method: 'post',
    data: data
  })
}

// 更新事件管理
export function updateAlarmEvent(data) {
  return request({
    url: '/system/alarm-event/update',
    method: 'put',
    data: data
  })
}

// 删除事件管理
export function deleteAlarmEvent(id) {
  return request({
    url: '/system/alarm-event/delete?id=' + id,
    method: 'delete'
  })
}

// 获得事件管理
export function getAlarmEvent(id) {
  return request({
    url: '/system/alarm-event/get?id=' + id,
    method: 'get'
  })
}

// 获得事件管理分页
export function getAlarmEventPage(query) {
  return request({
    url: '/system/alarm-event/page',
    method: 'get',
    params: query
  })
}

// 导出事件管理 Excel
export function exportAlarmEventExcel(query) {
  return request({
    url: '/system/alarm-event/export-excel',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}
// 批量删除事件 Excel
export function batchDeleteAlarmEvent(ids) {
  return request({
    url: '/system/alarm-event/batch-delete',
    method: 'put',
    data:ids
  })
}