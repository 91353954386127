var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-notice-box" }, [
    _vm.eventList.length > 0
      ? _c("div", { staticStyle: { "padding-top": "20px" } }, [
          _c("div", [
            _vm._v("设备位置： " + _vm._s(_vm.eventList[_vm.index].position)),
          ]),
          _c("div", [
            _vm._v("告警原因： " + _vm._s(_vm.eventList[_vm.index].alarmWord)),
          ]),
          _c("div", [
            _vm._v("设备编号： " + _vm._s(_vm.eventList[_vm.index].deviceNo)),
          ]),
          _c("div", [
            _vm._v("告警时间： " + _vm._s(_vm.eventList[_vm.index].time)),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticStyle: { position: "absolute", bottom: "20px", right: "20px" } },
      [
        _c(
          "el-button-group",
          [
            _vm.eventList.length > 1
              ? _c("el-button", {
                  attrs: {
                    disabled: _vm.index === 0,
                    size: "mini",
                    icon: "el-icon-arrow-left",
                  },
                  on: { click: _vm.backIndex },
                })
              : _vm._e(),
            _vm.eventList.length > 1
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: _vm.index === _vm.eventList.length - 1,
                      size: "mini",
                    },
                    on: { click: _vm.nextIndex },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-arrow-right el-icon--right",
                    }),
                  ]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: { click: _vm.handleInfo },
              },
              [_vm._v("详情")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }